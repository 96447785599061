window.onload = function () {
  var toggle = document.getElementById('dark-mode-toggle')
  var darkTheme = document.getElementById('dark-mode-theme')
  var paletteLight = {
          accent: "#000000",
          accentText: "#ffffff",
          footerHeader: "#F5F5F5",
          footerHeaderText: "#484848",
          box: "#ffffff",
          boxText: "#111111",
          boxLightText: "#AAAAAA",
          backgroundText: "#111111"
  }
  var paletteDark = {
          accent: "#979696",
          accentText: "#171717",
          footerHeader: "#1f1f1f",
          footerHeaderText: "#cac7c7",
          box: "#171717",
          boxText: "#979696",
          boxLightText: "#aaaaaa",
          backgroundText: "#979696"
  }

  if (typeof hyvor_talk !== 'undefined') {
    if (localStorage.getItem('dark-mode-storage') === 'dark') {
      hyvor_talk.setPalette(paletteDark);
    } else {
      hyvor_talk.setPalette(paletteLight);
    }
  }

  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    setTheme(localStorage.getItem('dark-mode-storage') || 'dark')
  } else {
    setTheme(localStorage.getItem('dark-mode-storage') || 'light')
  }

  toggle.addEventListener('click', () => {
    if (toggle.className === 'fas fa-moon') {
      setTheme('dark')
    } else if (toggle.className === 'fas fa-sun') {
      setTheme('light')
    }
  })

  function setTheme(mode) {
    localStorage.setItem('dark-mode-storage', mode)
    if (mode === 'dark') {
      darkTheme.disabled = false
      toggle.className = 'fas fa-sun'
      if (typeof hyvor_talk !== 'undefined') {
        hyvor_talk.setPalette(paletteDark);
      }
    } else if (mode === 'light') {
      darkTheme.disabled = true
      toggle.className = 'fas fa-moon'
      if (typeof hyvor_talk !== 'undefined') {
        hyvor_talk.setPalette(paletteLight);
      }
    }
  }
}